import React, {Component} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {DateRangePicker} from "./DateRange/";
import BarChart from './Barchart';
import LineChart from './Linechart';
import {getSourceStatsByDateRange, getDailySourceStatsByDateRange, getYearlySourceStats, sourceSelected, measurementSelected, getOrderTotalsByCountries} from '../actions';
import {Col, Grid, Row} from 'react-flexbox-grid';
import * as selectors from '../selectors';
import {ALL_DASHBOARD_MEASUREMENTS, DASHBOARD_ITEMS} from '../constants'
import DahboardLayout from './DahboardLayout';
import {addDays} from "date-fns";
import MapChart from "./MapChart";

class Sales extends Component {
    onDateRangeChange = (which, payload) => {
        this.setState({selection: which.selection}, () => {
            if(which.selection.startDate && which.selection.endDate) {
                this.props.getSourceStatsByDateRange(moment(which.selection.startDate), moment(which.selection.endDate));
                this.props.getDailySourceStatsByDateRange(moment(which.selection.startDate), moment(which.selection.endDate));
                this.props.getOrderTotalsByCountries(moment(which.selection.startDate), moment(which.selection.endDate));
            }
        });
    };
    onSourceClick = (node) => {
        this.props.sourceSelected(node.data.source);
    };

    constructor(props) {
        super(props);
        this.state = {
            selection: {
                startDate: addDays(new Date(), -7),
                endDate: new Date(),
                key: 'selection',
            },
        };
    }

    formatCharge = v => `£${Number((Math.round(v * 100) / 100).toFixed(2))}`;

    UNSAFE_componentWillMount() {
        this.props.getSourceStatsByDateRange(moment().startOf('day').add(-7, 'days'), moment().startOf('day'));
        this.props.getDailySourceStatsByDateRange(moment().startOf('day').add(-7, 'days'), moment().startOf('day'));
        this.props.getYearlySourceStats();
        this.props.getOrderTotalsByCountries(moment().startOf('day').add(-7, 'days'), moment().startOf('day'));
    }

    render() {
        const {soldBySource, soldBySubSource, dailySourceStatsLoading, soldBySourceYearly, soldBySourceMonthly, selectedSource, soldDailyBySource, soldDailyBySourceLoading} = this.props;
        const { orderTotalsByCountries, orderTotalsByCountriesLoading,  } = this.props;
        
        return (

                    <Grid fluid className="dashboardContainer">
                        <Row className="dashboardHeader">
                            <Col>
                                <div>
                                    <DateRangePicker
                                        onChange={this.onDateRangeChange}
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        className={'PreviewArea'}
                                        months={3}
                                        ranges={[this.state.selection]}
                                        direction="horizontal"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <DahboardLayout>
                            <div key={DASHBOARD_ITEMS.Sales1}>
                                <BarChart indexBy={'source'}
                                          description={'* Press on any Source to see all SubSources!'}
                                          data={soldBySource}
                                          loading={dailySourceStatsLoading}
                                          onClick={this.onSourceClick}
                                          title="Revenue by source"
                                          textDisplayKey="source"
                                />
                            </div>
                            <div key={DASHBOARD_ITEMS.Sales2}>
                                <BarChart indexBy={'subsource'}
                                          data={soldBySubSource}
                                          loading={dailySourceStatsLoading}
                                          title={selectedSource ? `Revenue by sub source: ${selectedSource}`: 'Select Source to view this chart!'}
                                          textDisplayKey="subsource"
                                />
                            </div>
                            <div key={DASHBOARD_ITEMS.Sales3}>
                                <LineChart data={soldBySourceYearly} title="Revenue by source yearly"
                                           textDisplayKey="id"
                                           legendLeftText={ALL_DASHBOARD_MEASUREMENTS[1].label}
                                           toolTipPrefix={'£'}
                                           customColors={{ scheme: 'dark2' }}
                                />
                            </div>
                            <div key={DASHBOARD_ITEMS.Sales4}>
                                <LineChart data={soldBySourceMonthly} title="Revenue by source monthly (this year)"
                                           textDisplayKey="id"
                                           legendLeftText={ALL_DASHBOARD_MEASUREMENTS[1].label}
                                           customColors={{ scheme: 'accent' }}
                                />
                            </div>
                            <div key={DASHBOARD_ITEMS.Sales5}>
                                <LineChart
                                    data={soldDailyBySource} title="Revenue by source Daily (selected date range)"
                                    textDisplayKey="id"
                                    legendLeftText={ALL_DASHBOARD_MEASUREMENTS[0].label}
                                    customColors={{ scheme: 'paired' }}
                                    bottomLegend={"Date"}
                                    loading={soldDailyBySourceLoading}
                                />
                            </div>
                            <div key={DASHBOARD_ITEMS.Sales6}>
                                <MapChart 
                                    title="Revenue by countries"
                                    data={orderTotalsByCountries} 
                                    loading={orderTotalsByCountriesLoading} 
                                    dataPropertyName="totalCharge" 
                                    formatter={this.formatCharge}
                                />
                            </div>
                        </DahboardLayout>
                    </Grid>
        );
    }
}

function mapStateToProps(state) {
    const soldBySource = selectors.getSoldBySource(state, ALL_DASHBOARD_MEASUREMENTS[1]);
    const soldBySubSource = selectors.getSoldBySubSource(state, ALL_DASHBOARD_MEASUREMENTS[1]);
    const soldDailyBySource = selectors.getDailySoldBySource(state, ALL_DASHBOARD_MEASUREMENTS[1]);
    const soldDailyBySourceLoading = selectors.getSourceStatsWithDatesLoading(state);
    const dailySourceStatsLoading = selectors.getDailySourceStatsLoading(state);
    const soldBySourceYearly = selectors.getSoldBySourceYearly(state, ALL_DASHBOARD_MEASUREMENTS[1]);
    const soldBySourceMonthly = selectors.getSoldBySourceMonthly(state, ALL_DASHBOARD_MEASUREMENTS[1]);
    const orderTotalsByCountries = selectors.getOrderTotalsByCountries(state);
    const orderTotalsByCountriesLoading = selectors.getOrderTotalsByCountriesLoading(state);
    return {
        soldBySource,
        soldBySubSource,
        dailySourceStatsLoading,
        soldDailyBySourceLoading,
        soldBySourceYearly,
        soldBySourceMonthly,
        soldDailyBySource,
        selectedSource: selectors.getSelectedSource(state),

        orderTotalsByCountries,
        orderTotalsByCountriesLoading
    };
}

const mapDispatchToProps = {
    getSourceStatsByDateRange, 
    getDailySourceStatsByDateRange, 
    getYearlySourceStats, 
    sourceSelected,
    measurementSelected,
    getOrderTotalsByCountries
}

export default connect(mapStateToProps, mapDispatchToProps)(Sales);
