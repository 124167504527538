import React, {Component} from "react";
import {connect} from "react-redux";
import moment from 'moment';
import { addDays } from 'date-fns';
import {DateRangePicker} from "./DateRange/";
import BarChart from "./Barchart";
import LineChart from "./Linechart";
import {getSourceStatsByDateRange, getDailySourceStatsByDateRange, getOrderTotalsByCountries, getYearlySourceStats, sourceSelected, measurementSelected} from "../actions";
import {Col, Grid, Row} from "react-flexbox-grid";
import * as selectors from "../selectors";
import {ALL_DASHBOARD_MEASUREMENTS, DASHBOARD_ITEMS} from "../constants";
import DahboardLayout from './DahboardLayout';
import MapChart from "./MapChart";

class Orders extends Component {
    onDateRangeChange = (which, payload) => {
        this.setState({selection: which.selection}, () => {
            if(which.selection.startDate && which.selection.endDate) {
                const newSpan = {
                    from: moment(which.selection.startDate),
                    to: moment(which.selection.endDate)
                };
                this.props.getSourceStatsByDateRange(newSpan.from, newSpan.to);
                this.props.getDailySourceStatsByDateRange(newSpan.from, newSpan.to, newSpan.from, newSpan.to);
                this.props.getOrderTotalsByCountries(newSpan.from, newSpan.to)
            }
        });
    };
    onSourceClick = (node) => {
        this.props.sourceSelected(node.data.source);
    };

    constructor(props) {
        super(props);
        this.state = {
            selection: {
                startDate: addDays(new Date(), -7),
                endDate: new Date(),
                key: 'selection',
            },
        };
    }

    UNSAFE_componentWillMount() {
        const initialSpan = {
            from: moment().startOf('day').add(-7, 'days'),
            to: moment().startOf('day')
        };
        this.props.getSourceStatsByDateRange(initialSpan.from, initialSpan.to);
        this.props.getDailySourceStatsByDateRange(initialSpan.from, initialSpan.to);
        this.props.getYearlySourceStats();
        this.props.getOrderTotalsByCountries(initialSpan.from, initialSpan.to);
    }

    render() {
        const {soldBySource, soldBySubSource, dailySourceStatsLoading, soldBySourceYearly, soldBySourceMonthly, selectedSource, soldDailyBySource, sourceStatsWithDatesLoading} = this.props;
        const { orderTotalsByCountries, orderTotalsByCountriesLoading } = this.props;
        return (
            <Grid fluid className="dashboardContainer">
                <Row className="dashboardHeader">
                    <Col>
                        <div>
                        <DateRangePicker
                            onChange={this.onDateRangeChange}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            className={'PreviewArea'}
                            months={3}
                            ranges={[this.state.selection]}
                            direction="horizontal"
                        />
                        </div>
                    </Col>
                </Row>
                <DahboardLayout>
                    <div key={DASHBOARD_ITEMS.Orders1}>
                        <BarChart
                            indexBy={"source"}
                            description={"* Press on any Source to see all SubSources!"}
                            data={soldBySource}
                            loading={dailySourceStatsLoading}
                            onClick={this.onSourceClick}
                            title="Orders count by source"
                            textDisplayKey="source"
                        />
                    </div>
                    <div key={DASHBOARD_ITEMS.Orders2}>
                        <BarChart
                            indexBy={"subsource"}
                            data={soldBySubSource}
                            loading={dailySourceStatsLoading}
                            title={selectedSource ? `Orders count by sub source: ${selectedSource}` : "Select Source to view this chart!"}
                            textDisplayKey="subsource"
                        />
                    </div>
                    <div key={DASHBOARD_ITEMS.Orders3}>
                        <LineChart
                            data={soldBySourceYearly} title="Orders count by source yearly (all time)"
                            textDisplayKey="id"
                            legendLeftText={ALL_DASHBOARD_MEASUREMENTS[0].label}
                            customColors={{ scheme: 'paired' }}
                        />
                    </div>
                    <div key={DASHBOARD_ITEMS.Orders4}>
                        <LineChart
                            data={soldBySourceMonthly} title="Orders count by source monthly (this year)"
                            textDisplayKey="id"
                            legendLeftText={ALL_DASHBOARD_MEASUREMENTS[0].label}
                            customColors={{ scheme: 'category10' }}
                        />
                    </div>
                    <div key={DASHBOARD_ITEMS.Orders5}>
                        <LineChart
                            data={soldDailyBySource} title="Orders received by source Daily (selected date range)"
                            textDisplayKey="id"
                            legendLeftText={ALL_DASHBOARD_MEASUREMENTS[0].label}
                            customColors={{ scheme: 'paired' }}
                            bottomLegend={"Date"}
                            loading={sourceStatsWithDatesLoading}
                        />
                    </div>
                    <div key={DASHBOARD_ITEMS.Orders6}>
                        <MapChart 
                            title="Orders count by countries"
                            data={orderTotalsByCountries} 
                            loading={orderTotalsByCountriesLoading} 
                            dataPropertyName="totalOrders"
                        />
                    </div>
                </DahboardLayout>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    const soldBySource = selectors.getSoldBySource(state, ALL_DASHBOARD_MEASUREMENTS[0]);
    const soldBySubSource = selectors.getSoldBySubSource(state, ALL_DASHBOARD_MEASUREMENTS[0]);
    const soldDailyBySource = selectors.getDailySoldBySource(state, ALL_DASHBOARD_MEASUREMENTS[0]);
    const dailySourceStatsLoading = selectors.getDailySourceStatsLoading(state);
    const sourceStatsWithDatesLoading = selectors.getSourceStatsWithDatesLoading(state);
    const soldBySourceYearly = selectors.getSoldBySourceYearly(state, ALL_DASHBOARD_MEASUREMENTS[0]);
    const soldBySourceMonthly = selectors.getSoldBySourceMonthly(state, ALL_DASHBOARD_MEASUREMENTS[0]);

    const orderTotalsByCountries = selectors.getOrderTotalsByCountries(state);
    const orderTotalsByCountriesLoading = selectors.getOrderTotalsByCountriesLoading(state);
    return {
        soldBySource,
        soldBySubSource,
        dailySourceStatsLoading,
        soldBySourceYearly,
        soldBySourceMonthly,
        soldDailyBySource,
        sourceStatsWithDatesLoading,
        selectedSource: selectors.getSelectedSource(state),

        orderTotalsByCountries,
        orderTotalsByCountriesLoading
    };
}

export default connect(mapStateToProps, {
    getSourceStatsByDateRange,
    getDailySourceStatsByDateRange,
    getYearlySourceStats,
    sourceSelected,
    measurementSelected,
    getOrderTotalsByCountries
})(Orders);
