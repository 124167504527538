import React, {Component} from 'react';
import {ResponsiveBar} from '@nivo/bar';
import Loader from '../../core/Components/Loader';
import ChartWrapper from '../../core/Components/ChartWrapper/ChartWrapper';
import _ from "lodash";

class BarChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data
        }
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.data, this.props.data)) {
            this.setState({ data: this.props.data });
        }
    }

    onSelectItems = data => {
        this.setState({ data });
    };

    render() {
        const {data, indexBy, loading, onClick, title, textDisplayKey, description, groupMode, valuePrefix, colors} = this.props;
        let content;
        if(loading){
            content = <Loader loading={loading}/>;
        }
        if (data && (!data.length || !this.state.data.length) && !loading) {
            content = <p className={'noDataMessage'}>Not enough data to show you this great chart :(</p>;
        }
        let total = 0;
        if(data && data.length > 0 && !loading && this.state.data.length) {
            const keys = Object.keys(data[0]).filter(i => i !== indexBy && i !== 'total' && i !== 'toolTips' && i !== 'itemTitle' && i !== 'categoryName' && i !== 'retailPrice' && i !== 'purchasePrice');
            content = (<ResponsiveBar
                data={this.state.data}
                keys={keys}
                indexBy={indexBy}
                margin={{top: 50, right: 130, bottom: 50, left: 60}}
                padding={0.15}
                colors={colors ? colors : ['#4292c6', '#9ecae1']}
                maxValue={'auto'}
                onClick={onClick}
                groupMode={groupMode ? groupMode : "stacked"}
                tooltip={({id, value, data}) => {
                    return (
                        <div>
                            <p><strong>{data[textDisplayKey]}</strong></p>
                            <p>{id.replace(/([A-Z])/g, ' $1').trim()}: {valuePrefix ? valuePrefix + value : value}</p>
                            {data.total && <p>Total: {data.total}</p>}
                        </div>);
                }}
                fill={[
                    {
                        match: {
                            id: 'processed'
                        },
                        id: 'processed'
                    },
                    {
                        match: {
                            id: 'opened'
                        },
                        id: 'opened'
                    }
                ]}
                borderColor={{from: 'color', modifiers: [['darker', 1.6]]}}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -15,
                    legendPosition: 'middle',
                    legendOffset: 32
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '',
                    legendPosition: 'middle',
                    legendOffset: -40
                }}
                labelSkipWidth={12}
                labelSkipHeight={16}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
            />);
            total = this.state.data.reduce((grandsum, item) => {
                return grandsum + keys.reduce((sum, key)=> sum + parseFloat(item[key]||0), 0) }, 0);
        }

        return (
            <ChartWrapper title={title} description={description} data={data} onSelectItems={this.onSelectItems} textDisplayKey={textDisplayKey} total={total}>
                {content}
            </ChartWrapper>
        );
    }
}


export default BarChart;
