import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { NAME } from './constants';
import * as actionTypes from './actionTypes';

const initialState = {
    token: null,
    tokenTtl: null,
    authorizing: false,
    paramsToken: null,
    redirectToOld: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTHORIZE_BY_APPLICATION.REQUEST:
            return {
                ...state,
                authorizing: true,
                paramsToken: action.payload
            };
        case actionTypes.AUTHORIZE_BY_APPLICATION.SUCCESS:
            return {
                ...state,
                token: action.payload.access_token,
                tokenTtl: action.payload.ttl,
                redirectToOld: action.payload.redirectToOld,
                authorizing: false
            };
        case actionTypes.AUTHORIZE_BY_APPLICATION.ERROR:
            return {
                ...state,
                token: null,
                tokenTtl: null,
                redirectToOld: null,
                authorizing: false
            };
        default:
            return state;
    }
};

const persistConfig = {
    key: NAME,
    storage,
    whitelist: ['paramsToken']
};

export default persistReducer(persistConfig, reducer);