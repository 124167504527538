import React, { useEffect, useState, useRef } from "react";
import _ from "lodash";
import { VectorMap } from "react-jvectormap";
import ChartWrapper from '../../core/Components/ChartWrapper/ChartWrapper';
import Loader from '../../core/Components/Loader';

const textDisplayKey = "source";

const MapChart = props => {
    const { title, data, dataPropertyName, loading, formatter } = props;

    const map = useRef(null);

    const [selectedSources, setSelectedSources] = useState(null);

    useEffect(() => {
        setInterval(() => {
            const timer = map.current && map.current.getMapObject().updateSize();
            return () => clearInterval(timer);
        }, 100);
    }, []);

    const preparedData = _(data)
        .mapValues(x => _(selectedSources || [])
            .mapValues(textDisplayKey)
            .map(y => _.get(x, `${y}.${dataPropertyName}`) || 0)
            .sum()
        )
        .pickBy(x => x > 0)
        .value();

    const sources = _(data || [])
        .flatMap(x => _.keys(x))
        .uniq()
        .map(x => ({ source: x }))
        .value();

    useEffect(() => {
        setSelectedSources(sources);
    }, [data]);

    const onSelectItems = data => {
        setSelectedSources(data);
    }

    const formatValue = v => !!formatter ? formatter(v) : v;

    if(loading) {
        return (
            <Loader loading={true}/>
        );
    }

    const onRegionTipShow = (e, el, code) => {
        const countryStats = data[code];
        let strings = [];
        if (!!countryStats) {
            strings = _.map(countryStats, (v, k) => {
                if (!_.some(selectedSources, s => s[textDisplayKey] === k)) {
                    return "";
                }
                return `<div>${k}: ${formatValue(v[dataPropertyName])}</div>`;
            })
        }
        
        return el.html(`<div style="text-align: center; font-weight: bold; margin-bottom: 5px;">${el.html()}</div>` + strings.join("") +  `<div style="font-weight: bold; margin-top: 5px;">Total: ${formatValue(preparedData[code] || 0)}</div>`);
    };

    const reportData = _.map(preparedData, (value, key) => ({ country: key, count: value}));

    return (
        <ChartWrapper title={title} reportData={reportData} data={sources} textDisplayKey={textDisplayKey} onSelectItems={onSelectItems} >
            <VectorMap
                map={"world_mill"}
                backgroundColor="#799BEA"
                ref={map}
                zoomOnScroll={true}
                containerStyle={{
                width: "100%",
                height: "100%"
                }}
                containerClassName="map"
                regionStyle={{
                initial: {
                    fill: "#ffffff",
                    "fill-opacity": 0.9,
                    stroke: "none",
                    "stroke-width": 0,
                    "stroke-opacity": 0
                },
                hover: {
                    "fill-opacity": 0.8,
                    cursor: "pointer"
                },
                selected: {
                    fill: "#2938bc" //color for the clicked country
                },
                selectedHover: {}
                }}
                regionsSelectable={false}
                series={{
                regions: [
                    {
                    values: preparedData, //this is your data
                    scale: ['#FFFD00', '#FF2929'], //your color game's here
                    normalizeFunction: "polynomial"
                    }
                ]
                }}
                onRegionTipShow={onRegionTipShow}
            />    
        </ChartWrapper>    
    );
};

export default MapChart;