import React from "react";
import {connect} from "react-redux";
import GridLayout from 'react-grid-layout';
import { withWindowSizeListener } from 'react-window-size-listener';
import _ from 'lodash';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { updateDashboardLayout } from "../actions";
import * as selectors from "../selectors";

class DahboardLayout extends React.PureComponent {
    onLayoutChange = layout => {
        const oldLayout = _.map(this.props.layout, item => ({ itemId: item.itemId, x: item.x, y: item.y, h: item.h, w: item.w, visible: true }));
        const previousLayout = _.cloneDeep(this.props.layout);
        const newLayout = _.map(previousLayout, oldOtem => {
            const newItem = _.find(layout, item => _.toInteger(item.i) === oldOtem.itemId);
            return newItem ?
                { itemId: oldOtem.itemId, x: newItem.x, y: newItem.y, h: newItem.h, w: newItem.w, visible: true } :
                { itemId: oldOtem.itemId, x: oldOtem.x, y: oldOtem.y, h: oldOtem.h, w: oldOtem.w, visible: true };
        });
        if (!_.isEqual(oldLayout, newLayout)) {
            this.props.updateDashboardLayout(newLayout);
        }
    };

    get layout() {
        const { layout } = this.props;
        return _.map(layout, ({ x, y, h, w, itemId }) => ({ x, y, h, w, i: _.toString(itemId) }));
    }

    render() {
        const { children, windowSize: { windowWidth }, layout } = this.props;
        return layout.length ?
            <GridLayout
                className="layout"
                layout={this.layout}
                cols={6}
                width={windowWidth-40}
                autoSize={true}
                rowHeight={300}
                isDraggable={true}
                isResizable={true}
                onLayoutChange={this.onLayoutChange}
                margin={[10,10]}>
                {children}
            </GridLayout> : null;
    }
}

function mapStateToProps(state) {
    return {
        layout: selectors.getDashboardLayout(state)
    };
}

export default withWindowSizeListener(connect(mapStateToProps, { updateDashboardLayout })(DahboardLayout));