import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route} from 'react-router';
import _ from 'lodash';
import Dashboard from './Modules/Dashboard/Components/Dashboard';
import * as authSelectors from './Modules/Auth/selectors';
import {authorize} from './Modules/Auth/actions';
import Loader from './Modules/core/Components/Loader';
import ClearCache from './Modules/ClearCache';

let timer = null;
class App extends Component {
    componentWillMount() {
        const {searchParams} = this.props;
        let hashes = searchParams.slice(searchParams.indexOf('?') + 1).split('&');
        let params = {};
        hashes.forEach(hash => {
            let [key, val] = hash.split('=');
            params[key] = decodeURIComponent(val);
        });
        if (!this.props.token || this.props.paramsToken !== params['token']) {
            this.props.authorize(params['token']);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.authorizing === false && this.props.authorizing === true) {
            if (nextProps.tokenTtl) {
                timer = setTimeout(() => this.props.authorize(nextProps.paramsToken), nextProps.tokenTtl * 1000);
            }
        }
        if (nextProps.redirectToOld) {
            if(nextProps.redirectToOld.includes('pleaseReinstallApp')){
                document.location.href = `${nextProps.redirectToOld}`;
            }else{
                document.location.href = `${nextProps.redirectToOld}/?token=${nextProps.paramsToken}`;
            }

        }
    }

    componentWillUnmount() {
        if (timer) {
            clearTimeout(timer);
        }
    }

    render() {
        const {authorizing, token} = this.props;
        return (
            <div>
                {authorizing || !token ?
                    <Loader loading={true}/>
                    : <Route exact path='/' component={Dashboard} />
                }
                {process.env.NODE_ENV === 'production' ?
                    <ClearCache />
                    : null
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const token = authSelectors.getAuthorizeToken(state);
    const tokenTtl = authSelectors.getAuthorizeTokenTtl(state);
    const paramsToken = authSelectors.getParamsToken(state);
    const authorizing = authSelectors.isAuthorizing(state);
    const searchParams = _.get(state, 'router.location.search', '');
    const redirectToOld = authSelectors.getRedirectToOld(state)
    return {
        token,
        tokenTtl,
        searchParams,
        authorizing,
        paramsToken,
        redirectToOld
    };
}

export default connect(mapStateToProps, {authorize})(App);
