import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { NAME, ALL_DASHBOARD_MEASUREMENTS } from './constants';
import * as actionTypes from './actionTypes';

const initialState = {
    dailySourceStats: [],
    dailySourceStatsLoading: false,

    sourceStatsWithDates: [],
    sourceStatsWithDatesLoading: false,

    orderTotalsByCountries: {},
    orderTotalsByCountriesLoading: false,

    yearlySourceStats: [],
    yearlySourceStatsLoading: false,
    selectedSource: null,
    dashboardMeasurement: ALL_DASHBOARD_MEASUREMENTS[0],



    user6MonthStats: [],
    user6MonthStatsLoading: false,
    user7DaysStats: [],
    user7DaysStatsLoading: false,
    user12HoursStats: [],
    user12HoursStatsLoading: false,

    settings: null,
    settingsLoading: false,


    inventoryTop10ForYear: null,
    inventoryTop10ForYearLoading: false,
    inventoryTop10ThisMonth: null,
    inventoryTop10ThisMonthLoading: false,
    inventorySoldStats: [],
    inventorySoldStatsLoading: false,
    
    
    layout: [],


    isSendingFeedback: false,
    isSendingFeedbackMobile: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_SOURCE_STATS_BE_DATE_RANGE.REQUEST:
            return {
                ...state,
                dailySourceStatsLoading: true
            };
        case actionTypes.GET_SOURCE_STATS_BE_DATE_RANGE.SUCCESS:
            return {
                ...state,
                dailySourceStats: action.payload,
                dailySourceStatsLoading: false,
                selectedSource: action.payload[0] && action.payload[0].source
            };
        case actionTypes.GET_SOURCE_STATS_BE_DATE_RANGE.ERROR:
            return {
                ...state,
                dailySourceStatsLoading: false,
                selectedSource: null
            };
        case actionTypes.GET_DAILY_SOURCE_STATS_BE_DATE_RANGE.REQUEST:
            return {
                ...state,
                sourceStatsWithDatesLoading: true
            };
        case actionTypes.GET_DAILY_SOURCE_STATS_BE_DATE_RANGE.SUCCESS:
            return {
                ...state,
                sourceStatsWithDates: action.payload,
                sourceStatsWithDatesLoading: false
            };
        case actionTypes.GET_DAILY_SOURCE_STATS_BE_DATE_RANGE.ERROR:
            return {
                ...state,
                sourceStatsWithDatesLoading: false
            };

        case actionTypes.GET_ORDERS_TOTALS_BY_COUNTRIES.REQUEST:
            return {
                ...state,
                orderTotalsByCountriesLoading: true
            };
        case actionTypes.GET_ORDERS_TOTALS_BY_COUNTRIES.SUCCESS:
            return {
                ...state,
                orderTotalsByCountries: action.payload,
                orderTotalsByCountriesLoading: false
            };
        case actionTypes.GET_ORDERS_TOTALS_BY_COUNTRIES.ERROR:
            return {
                ...state,
                orderTotalsByCountriesLoading: false
            };

        case actionTypes.GET_YEARLY_SOURCE_STATS.REQUEST:
            return {
                ...state,
                yearlySourceStatsLoading: true
            };
        case actionTypes.GET_YEARLY_SOURCE_STATS.SUCCESS:
            return {
                ...state,
                yearlySourceStats: action.payload,
                yearlySourceStatsLoading: false
            };
        case actionTypes.GET_YEARLY_SOURCE_STATS.ERROR:
            return {
                ...state,
                yearlySourceStatsLoading: false
            };
        case actionTypes.SOURCE_CHANGED.SUCCESS:
            return {
                ...state,
                selectedSource: action.payload
            };

        case actionTypes.MEASUREMENT_CHANGED.SUCCESS:
            return {
                ...state,
                dashboardMeasurement: action.payload
            };




            //users

        case actionTypes.GET_6_MONTH_USER_STATS.REQUEST:
            return {
                ...state,
                user6MonthStatsLoading: true
            };
        case actionTypes.GET_6_MONTH_USER_STATS.SUCCESS:
            return {
                ...state,
                user6MonthStats: action.payload,
                user6MonthStatsLoading: false
            };
        case actionTypes.GET_6_MONTH_USER_STATS.ERROR:
            return {
                ...state,
                user6MonthStatsLoading: false
            };
        case actionTypes.GET_7_Days_USER_STATS.REQUEST:
            return {
                ...state,
                user7DaysStatsLoading: true
            };
        case actionTypes.GET_7_Days_USER_STATS.SUCCESS:
            return {
                ...state,
                user7DaysStats: action.payload,
                user7DaysStatsLoading: false
            };
        case actionTypes.GET_7_Days_USER_STATS.ERROR:
            return {
                ...state,
                user7DaysStatsLoading: false
            };
        case actionTypes.GET_12_HOURS_USER_STATS.REQUEST:
            return {
                ...state,
                user12HoursStatsLoading: true
            };
        case actionTypes.GET_12_HOURS_USER_STATS.SUCCESS:
            return {
                ...state,
                user12HoursStats: action.payload,
                user12HoursStatsLoading: false
            };
        case actionTypes.GET_12_HOURS_USER_STATS.ERROR:
            return {
                ...state,
                user12HoursStatsLoading: false
            };
            //users


        // SETTINGS
        case actionTypes.GET_SETTINGS.REQUEST:
            return {
                ...state,
                settingsLoading: true
            };
        case actionTypes.GET_SETTINGS.SUCCESS:
            return {
                ...state,
                settings: action.payload,
                settingsLoading: false
            };
        case actionTypes.GET_SETTINGS.ERROR:
            return {
                ...state,
                settingsLoading: false
            };

        case actionTypes.SET_SETTINGS.REQUEST:
            return {
                ...state,
            };
        case actionTypes.SET_SETTINGS.SUCCESS:
            return {
                ...state,
                settings: action.payload
            };
        case actionTypes.SET_SETTINGS.ERROR:
            return {
                ...state,
            };
        // SETTINGS


        // INVENTORY

        case actionTypes.GET_INVENTORY_TOP_10_FOR_YEAR.REQUEST:
            return {
                ...state,
                inventoryTop10ForYearLoading: true
            };
        case actionTypes.GET_INVENTORY_TOP_10_FOR_YEAR.SUCCESS:
            return {
                ...state,
                inventoryTop10ForYearLoading: false,
                inventoryTop10ForYear: action.payload
            };
        case actionTypes.GET_INVENTORY_TOP_10_FOR_YEAR.ERROR:
            return {
                ...state,
                inventoryTop10ForYearLoading: false
            };

        case actionTypes.GET_INVENTORY_TOP_10_THIS_MONTH.REQUEST:
            return {
                ...state,
                inventoryTop10ThisMonthLoading: true
            };
        case actionTypes.GET_INVENTORY_TOP_10_THIS_MONTH.SUCCESS:
            return {
                ...state,
                inventoryTop10ThisMonthLoading: false,
                inventoryTop10ThisMonth: action.payload
            };
        case actionTypes.GET_INVENTORY_TOP_10_THIS_MONTH.ERROR:
            return {
                ...state,
                inventoryTop10ThisMonthLoading: false
            };

        case actionTypes.GET_INVENTORY_SOLD_STATS.REQUEST:
            return {
                ...state,
                inventorySoldStatsLoading: true
            };
        case actionTypes.GET_INVENTORY_SOLD_STATS.SUCCESS:
            return {
                ...state,
                inventorySoldStatsLoading: false,
                inventorySoldStats: action.payload
            };
        case actionTypes.GET_INVENTORY_SOLD_STATS.ERROR:
            return {
                ...state,
                inventorySoldStatsLoading: false
            };

        // INVENTORY



        case actionTypes.GET_DASHBOARD_LAYOUT.SUCCESS:
        case actionTypes.UPDATE_DASHBOARD_LAYOUT.SUCCESS:
            return {
                ...state,
                layout: action.payload.data
            };


        case actionTypes.SEND_FEEDBACK.REQUEST:
            return { ...state, isSendingFeedback: true };
        case actionTypes.SEND_FEEDBACK.SUCCESS:
        case actionTypes.SEND_FEEDBACK.ERROR:
            return { ...state, isSendingFeedback: false };
        case actionTypes.SEND_FEEDBACK_MOBILE.REQUEST:
            return { ...state, isSendingFeedbackMobile: true };
        case actionTypes.SEND_FEEDBACK_MOBILE.SUCCESS:
        case actionTypes.SEND_FEEDBACK_MOBILE.ERROR:
            return { ...state, isSendingFeedbackMobile: false };


        default:
            return state;
    }
};

const persistConfig = {
    key: NAME,
    storage,
    whitelist: []
};

export default persistReducer(persistConfig, reducer);